import type { CreateCreditAccountParams } from "mkm-api";
import { InputData } from "~/components/Content/Account/Credit/CreditAccountInterfaces";

const creditAccountData = useState<InputData>("creditAccountData", () => ({
  email: "",
  businessType: "",
  registrationNumber: "",
  businessName: "",
  businessFormationDate: null,
  businessMovedDate: null,
  creditLimit: "",
  inTrade: null,
  address: {
    line1: "",
    line2: "",
    line3: "",
    city: "",
    county: "",
    postcode: "",
  },
  preferences: {
    email: false,
    phone: false,
    sms: false,
    post: false,
  },
  termsAndConditions: false,
  purchaseOrder: false,
  accountBranchId: 0,
  accountBranchName: "",
  profession: "",
  firstName: "",
  secondName: "",
  dob: null,
  mobile: "",
  landline: "",
  faxNumber: "",
  otherAccounts: "",
  success: false,
}));

const resetCreditAccountData = () => {
  creditAccountData.value = {
    email: "",
    businessType: "",
    registrationNumber: "",
    businessName: "",
    businessFormationDate: null,
    businessMovedDate: null,
    creditLimit: "",
    inTrade: null,
    address: {
      line1: "",
      line2: "",
      line3: "",
      city: "",
      county: "",
      postcode: "",
    },
    preferences: {
      email: false,
      phone: false,
      sms: false,
      post: false,
    },
    termsAndConditions: false,
    purchaseOrder: false,
    accountBranchId: 0,
    accountBranchName: "",
    profession: "",
    firstName: "",
    secondName: "",
    dob: null,
    mobile: "",
    landline: "",
    faxNumber: "",
    otherAccounts: "",
    success: false,
  };
};

const registerCreditAccount = async (payload: InputData) => {
  const mappedPayload: CreateCreditAccountParams = {
    email: payload.email,
    preferredBranch: String(payload.accountBranchId),
    preferredBranchName: payload.accountBranchName,
    businessFormBusinessType: payload.businessType,
    businessFormTradingName: payload.businessName,
    // eslint-disable-next-line etc/no-assign-mutated-array
    businessFormDateFormed: (payload.businessFormationDate as string).split("/").reverse().join("-"),
    businessFormRequiredCreditLimit: Number(payload.creditLimit),
    businessFormAddress1: payload.address.line1,
    businessFormAddress2: payload.address.line2,
    businessFormAddress3: payload.address.line3,
    businessFormCity: payload.address.city,
    businessFormCounty: payload.address.county,
    businessFormPostcode: payload.address.postcode,
    // eslint-disable-next-line etc/no-assign-mutated-array
    businessFormAddressDateMoveIn: (payload.businessMovedDate as string).split("/").reverse().join("-"),
    businessFormBestDescribingOfTheBusiness: payload.profession,
    limitedRegNumber: payload.registrationNumber,
    personalFormFirstName: payload.firstName,
    personalFormSecondName: payload.secondName,
    // eslint-disable-next-line etc/no-assign-mutated-array
    personalFormDateOfBirth: (payload.dob as string).split("/").reverse().join("-"),
    personalFormMobile: payload.mobile,
    personalFormLandline: payload.landline,
    personalFormFax: payload.faxNumber,
    personalFormOtherCreditAccount: payload.otherAccounts,
    bankFormIsEmailPreferredContact: payload.preferences.email,
    bankFormIsPhonePreferredContact: payload.preferences.phone,
    bankFormIsSMSPreferredContact: payload.preferences.sms,
    bankFormIsPostPreferredContact: payload.preferences.post,
  };

  const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
  const headers = useRequestHeaders();

  const response = await useAsyncData("createCreditAccount", () =>
    $fetch<any>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/createCreditAccount`, {
      headers,
      body: JSON.stringify(mappedPayload),
      method: "POST",
      credentials: "include",
    }),
  );

  if (response.status.value === "error") {
    throw createError({
      statusCode: 422,
      message: "Unable to create credit account",
      statusMessage: "Unable to create credit account",
      fatal: false,
    });
  } else {
    creditAccountData.value.success = true;
  }
};

export function useCreditAccount() {
  return {
    registerCreditAccount,
    creditAccountData,
    resetCreditAccountData,
  };
}
